.fa-facebook, .fa-facebook-square {
    color: #3b5998
}
.fa-twitter, .fa-twitter-square {
    color: #00aced
}
.fa-github{
    color: #070709;
}
.fa-medium {
    color: #000
}
.fa-linkedin, .fa-linkedin-square {
    color: #007bb6
}
.fa-instagram {
    color: #517fa4;
}
.fa-spotify {
    color: #1ED760;
}
.fa-goodreads {
    color: #553b08;
}